import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { loader, iconBeat} from '../../assets/images';
import axios from 'axios';

export const ModalSection = ({ open, onCloseModal }) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        brief: '',
        phone: '',
    });

    const onSubmitFunc = async (e) => {
        e.preventDefault();

        console.log('formData', formData);
        if (formData.phone.length < 9) {
            setErrors(true);
        } else {
            const currentUrl = window.location.href;
            const protocol = window.location.protocol; // "https:"
            const hostname = window.location.hostname; // "creativeghostwriters.org"

            // Construct the base URL
            const baseUrl = `${protocol}//${hostname}`;
            const queryStringFormData = new URLSearchParams(formData).toString();

            let finalReq = {
                ...formData,
                source: `${currentUrl}${queryStringFormData}`,
                domain: baseUrl,
                lead_url: currentUrl,
                url: `${currentUrl}${queryStringFormData}`,
            };
            try {
                setLoading(true);
                const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=949201';
                const config = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                };

                const formDataString = new URLSearchParams(finalReq).toString();

                const response = await axios.post(apiUrl, formDataString, config);
                if (response.status == 200) {
                    // toast.success("Thank you for filling out the information");
                    // console.log(response.data.data.id);
                    const queryString = new URLSearchParams(
                        response.data.data
                    ).toString();
                    console.log('queryString', queryString);
                    setLoading(false);
                    setErrors(false);
                    setFormData({
                        name: '',
                        email: '',
                        message: '',
                        phone: '',
                    });
                    // naviagte(`/thank-you?${queryString}`)
                    window.location.href = `https://www.professionalwikicreators.com/thankyou?${queryString}`;
                }
                console.log('responseresponseresponse', response);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };


    return (
        <Modal className='popupMain signUpPop'
            show={open} onHide={onCloseModal}
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <img class="wki" src={iconBeat}/> 
            <div class="popup-inner"> <a class="closePop" href="javascript:;" onClick={onCloseModal}>X</a> </div>
            <div class="pp-wrapp">
                <div class="popform-holder">
                    <div class="popTxt m1-h p2">
                        <h4>Wait! Looking for an Amazing Discount?</h4>
                    </div>
                    <div class="pop-wrap">
                        <form class="form_submission" method="POST" onSubmit={onSubmitFunc}>
                            <div class="pop-filed">
                                <input type="text" name="name" placeholder="Name" required onChange={(e) => setFormData({ ...formData, name: e.target.value })} />
                            </div>
                            <div class="pop-filed">
                                <input type="email" placeholder="Email Address" name="email" required onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                            </div>
                            <div class="pop-filed">
                                <input type="number" name="phone" minlength="10" maxlength="12" class="phone-number"
                                    placeholder="Contact Number" required onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                            </div>
                            <div class="pop-filed" id="fixed_box">
                                <input type="text" name="message" placeholder="Message" required onChange={(e) => setFormData({ ...formData, message: e.target.value })} />
                            </div>
                            {loading == true ? (
                                <div class="loader">
                                    <img alt="loader" src={loader} />
                                </div>
                            ) : (
                                <div class="pop-btn">
                                    <input type="submit" class="btn-form" name="submit" value="Activate Now" />
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </Modal >
    );
};


