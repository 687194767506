import React from 'react';
import { logoEditing, logoMaintainence, logoPageCreation, logoPageTranslation, logoPageUpdates, logoResearch } from '../../assets/images';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function TestimonialSlider() {
    const settings = {
        loop: !0,
        nav: false,
        dots: true,
        margin: 10,
        responsiveClass: !0,
        autoplay: !0,
        autoplayHoverPause: !0,
        smartSpeed: 500,
        singleItem: true,
        navSpeed: 500,
        responsive: { 0: { items: 3 }, 1300: { items: 3 }, 1e3: { items: 3 }, 768: { items: 2 }, 992: { items: 2 }, 300: { items: 1 } },
    }
    return (
        <OwlCarousel class="testimonials-silder owl-carousel owl-theme" {...settings}>
            <li>
                <div class="testimonials-four-card">
                    <div class="image"> <img src={logoResearch} alt="" /> </div>
                    <div class="testimonials-four-card__content">
                        <div class="name">Research &amp; Copywriting</div>
                        <p>Before working on Wikipedia pages, our research team analyzes, identifies,
                            and procures all the required information to ensure top-notch copywriting.
                        </p>
                    </div>
                </div>
            </li>
            <li>
                <div class="testimonials-four-card">
                    <div class="image"> <img src={logoPageTranslation} alt="" />
                    </div>
                    <div class="testimonials-four-card__content">
                        <div class="name">Maintenance &amp; Monitoring</div>
                        <p>Our team monitors and maintains the page regularly to keep you safe from any
                            unknown and illicit alterations on your Wikipedia page.</p>
                    </div>
                </div>
            </li>
            <li>
                <div class="testimonials-four-card">
                    <div class="image"> <img src={logoPageCreation} alt="" /> </div>
                    <div class="testimonials-four-card__content">
                        <div class="name">Page Translation</div>
                        <p>Get your page translated to several languages from our language experts with
                            an excellent track record of accurate translation with complete quality
                            assurance.</p>
                    </div>
                </div>
            </li>
            <li>
                <div class="testimonials-four-card">
                    <div class="image"> <img src={logoMaintainence} alt="" /> </div>
                    <div class="testimonials-four-card__content">
                        <div class="name">Page Editing</div>
                        <p>We add all the necessary information with an exquisite quality of writing in
                            the shortest time possible.</p>
                    </div>
                </div>
            </li>
            <li>
                <div class="testimonials-four-card">
                    <div class="image"> <img src={logoEditing} alt="" /></div>
                    <div class="testimonials-four-card__content">
                        <div class="name">Page Creation</div>
                        <p>A page crafted to comply with all Wikipedia guidelines and which is bound to
                            add value to the brand/individual's profile.</p>
                    </div>
                </div>
            </li>
            <li>
                <div class="testimonials-four-card">
                    <div class="image"> <img src={logoPageUpdates} alt="" /></div>
                    <div class="testimonials-four-card__content">
                        <div class="name">Page Updates</div>
                        <p>We make sure that you have flawless information on your Wikipedia page and
                            keep it up-to-date regularly. </p>
                    </div>
                </div>
            </li>
        </OwlCarousel>
    )
}