import React from 'react';
import { resourceAuthor1, resourceAuthor2, resourceAuthor3, resourceAuthor4, resourceAuthor5, resourceAuthor6} from '../../assets/images';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { WEBSITE_NAME } from '../../config';

export default function TestimonialCarousel() {
    const settings = {
        loop: false,
        nav: false,
        dots: true,
        margin: 10,
        responsiveClass: !0,
        autoplay: !0,
        autoplayHoverPause: !0,
        smartSpeed: 500,
        singleItem: true,
        navSpeed: 500,
        responsive: { 0: { items: 3 }, 1300: { items: 3 }, 1e3: { items: 2 }, 768: { items: 1 }, 992: { items: 1 }, 300: { items: 1 } },
    }
    return (
        <OwlCarousel class="testimonials-carousel owl-carousel owl-theme" {...settings}>
            <div class="testi-block">
                <div class="inner">
                    <div class="icon"><span>“</span></div>
                    <div class="info">
                        <div class="image">
                            <a href="#"><img src={resourceAuthor1} alt="" /></a>
                        </div>
                        <div class="name">Line M. Berg</div>
                    </div>
                    <div class="text">Tremendous work and communication by the team! Always prompt to answer
                        any questions and gives best advice throughout the tenure. Thank you, it is a
                        pleasure working with them!</div>
                </div>
            </div>
            <div class="testi-block">
                <div class="inner">
                    <div class="icon"><span>“</span></div>
                    <div class="info">
                        <div class="image">
                            <a href="#"><img src={resourceAuthor2} alt="" /></a>
                        </div>
                        <div class="name">Aidan Morgan</div>
                    </div>
                    <div class="text">{WEBSITE_NAME} has a team of hard workers who really knows
                        their
                        stuff when it comes to Wikipedia pages. They got the job done in the time provided
                        by them. Highly recommended! </div>
                </div>
            </div>
            <div class="testi-block">
                <div class="inner">
                    <div class="icon"><span>“</span></div>
                    <div class="info">
                        <div class="image">
                            <a href="#"><img src={resourceAuthor3} alt="" /></a>
                        </div>
                        <div class="name">Ruben M. Smith</div>
                    </div>
                    <div class="text">I desperately needed a Wikipedia page for one of my successfully
                        running brand. I turned to {WEBSITE_NAME} for help. Their team did not
                        disappoint me. They simplified the process and guided me through each and every step
                        so that I could create a page that had an immediate impact on my brands reputation
                        and publicity.</div>
                </div>
            </div>
            <div class="testi-block">
                <div class="inner">
                    <div class="icon"><span>“</span></div>
                    <div class="info">
                        <div class="image">
                            <a href="#"><img src={resourceAuthor4} alt="" /></a>
                        </div>
                        <div class="name">Kelly Waddell</div>
                    </div>
                    <div class="text">{WEBSITE_NAME} maintained and monitored my already created
                        wiki
                        page much better. Their knowledge and skills in outreach made me feel satisfied.
                        Their work helped us a lot! </div>
                </div>
            </div>
            <div class="testi-block">
                <div class="inner">
                    <div class="icon"><span>“</span></div>
                    <div class="info">
                        <div class="image">
                            <a href="#"><img src={resourceAuthor5} alt="" /></a>
                        </div>
                        <div class="name">Joshua Johnsonn</div>
                    </div>
                    <div class="text">I wanted my Wikipedia page to be easily accessible and understandable
                        by my followers, and have authentic content to maintain my credibility. Not only
                        that, I asked if my page will be maintained for updates over the time period, I will
                        definitely counting on these professionals. {WEBSITE_NAME} really helped
                        me a
                        lot!</div>
                </div>
            </div>
            <div class="testi-block">
                <div class="inner">
                    <div class="icon"><span>“</span></div>
                    <div class="info">
                        <div class="image">
                            <a href="#"><img src={resourceAuthor6} alt="" /></a>
                        </div>
                        <div class="name">Afifah Nafisah Maalouf</div>
                    </div>
                    <div class="text">They have experienced Wikipedia writers on board who actually know the
                        process and terminologies that can fulfill your expectations. I have worked with
                        them and got truly impressed. Highly recommended!</div>
                </div>
            </div>
        </OwlCarousel>
    )
}