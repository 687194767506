// Import images from different paths

// From './'
export const favicon = require('./favicon.webp');
export const footerLogo = require('./footerlogo.webp');
export const indexJs = require('./index.js');
export const logo = require('./logo.webp');
export const popH = require('./pop-h.png');
export const popNewImg1 = require('./pop-new-img1.png');
export const wiki = require('./wiki.png');
export const wikimp4 = require('./wiki-g.mp4');
export const loader = require('./loader.gif');

// From './awrad/'
export const awradBannerTrustImg1 = require('./awrad/banner-trust-img-1.png');
export const awradBannerTrustImg2 = require('./awrad/banner-trust-img-2.png');
export const awradBannerTrustImg3 = require('./awrad/banner-trust-img-3.png');

// From './background/'
export const bgBShape1 = require('./background/b-shape-1.png');
export const bgBShape2 = require('./background/b-shape-2.png');
export const bgContactBg1 = require('./background/contact-bg-1-1.png');
export const bgCta2Bg1 = require('./background/cta-2-bg-1-1.png');
export const bgFooter = require('./background/footer-bg.png');
export const bgHomePortfolio1 = require('./background/home-portfolio-bg-1-1.png');
export const bgImage4 = require('./background/image-4.jpg');
export const bgImage5 = require('./background/image-5.jpg');
export const bgPattern1 = require('./background/pattern-1.png');
export const bgPattern2 = require('./background/pattern-2.png');
export const bgWeDoSectionDark = require('./background/we-do-section-bg-dark.jpg');

// From './icons'
export const iconBeat = require('./icons/beat.png');
export const iconCall = require('./icons/call-icon.png');
export const iconCallShape1 = require('./icons/call-to-shape-1.png');
export const iconCallShape2 = require('./icons/call-to-shape-2.png');
export const iconChatDark = require('./icons/chat-icon-dark.png');
export const iconChat = require('./icons/chat-icon.png');
export const iconCtaBg1 = require('./icons/cta-bg-1-1.png');
export const iconCurveDownLeft = require('./icons/curve-down-left.png');
export const iconDmca = require('./icons/dmca.png');
export const iconImageBg = require('./icons/image-bg.svg');
export const iconPaypal = require('./icons/paypal-pic.png');
export const iconPreloader = require('./icons/preloader.png');
export const iconShape1 = require('./icons/shape-1.png');

// From './logo'
export const logoAffordable = require('./logo/affordable.png');
export const logoEditing = require('./logo/editing.png');
export const logoFlowchart = require('./logo/Flowchart.png');
export const logoFor = require('./logo/for.png');
export const logoForWebp = require('./logo/for.webp');
export const logoFor1 = require('./logo/for1.png');
export const logoFor1Webp = require('./logo/for1.webp');
export const logoFor2 = require('./logo/for2.png');
export const logoFor2Webp = require('./logo/for2.webp');
export const logoFor3 = require('./logo/for3.png');
export const logoFor3Webp = require('./logo/for3.webp');
export const logoFor4 = require('./logo/for4.png');
export const logoFor4Webp = require('./logo/for4.webp');
export const logoFor5 = require('./logo/for5.png');
export const logoFor5Webp = require('./logo/for5.webp');
export const logoMaintainence = require('./logo/maintainence.png');
export const logoPageCreation = require('./logo/page-creation.png');
export const logoPageTranslation = require('./logo/page-translation.png');
export const logoPageUpdates = require('./logo/page-updates.png');
export const logoResearch = require('./logo/reseacrh.png');
export const logoSatisfaction = require('./logo/satisfaction.png');
export const logoServices = require('./logo/sevices.png');
export const logoVastExperience = require('./logo/vast-experience.png');

// From './main-slider'
export const sliderB2Shape1 = require('./main-slider/b-2-shape-1.png');
export const sliderB2Shape2 = require('./main-slider/b-2-shape-2.png');
export const sliderB2Shape3 = require('./main-slider/b-2-shape-3.png');
export const sliderB2Shape4 = require('./main-slider/b-2-shape-4.png');
export const sliderB2Shape5 = require('./main-slider/b-2-shape-5.png');
export const sliderB2Shape6 = require('./main-slider/b-2-shape-6.png');
export const sliderB3Shape = require('./main-slider/b-3-shape.png');
export const sliderBanerPo = require('./main-slider/baner-po.webp');
export const sliderLeftCurveLine = require('./main-slider/left-curve-line.png');
export const sliderRightCurvedBg = require('./main-slider/right-curved-bg.png');
export const sliderRightWhiteCurve = require('./main-slider/right-white-curve.png');
export const sliderSlide2Pattern1 = require('./main-slider/slide-2-pattern-1.png');

// From './resource'
export const resourceAuthor1 = require('./resource/author-1.jpg');
export const resourceAuthor2 = require('./resource/author-2.jpg');
export const resourceAuthor3 = require('./resource/author-3.jpg');
export const resourceAuthor4 = require('./resource/author-4.jpg');
export const resourceAuthor5 = require('./resource/author-5.jpg');
export const resourceAuthor6 = require('./resource/author-6.jpg');

// From './update-1-12-2020/shapes/'
export const update20MenuTopShadow = require('./update-1-12-2020/shapes/menu-top-shadow.png');
export const update20TestiArrowHover = require('./update-1-12-2020/shapes/testi-arrow-hover.png');
export const update20TestiArrow = require('./update-1-12-2020/shapes/testi-arrow.png');

// From './update-26-02-2021/shapes/'
export const update21PortfolioHorizontalArrow = require('./update-26-02-2021/shapes/portfolio-horizontal-arrow.png');
