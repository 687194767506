import React, { useState, useEffect } from 'react';
import { 
    logoFor, logoFor1, logoFor2, logoFor3, logoFor4, logoFor5 
} from '../../assets/images';
import 'animate.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// Reusable Counter Component
const Counter = ({ stop, speed, title }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        const startCount = () => {
            const start = 0;
            const duration = parseInt(speed);
            const end = parseInt(stop);
            let startTime = null;

            const step = (timestamp) => {
                if (!startTime) startTime = timestamp;
                const progress = timestamp - startTime;
                const currentCount = Math.min(Math.floor((progress / duration) * end), end);
                setCount(currentCount);

                if (progress < duration) {
                    requestAnimationFrame(step);
                }
            };

            requestAnimationFrame(step);
        };

        startCount();
    }, [speed, stop]);

    return (
        <div className="content-box">
            <div className="count-outer count-box">
                <span className="count-text">{count}</span>
            </div>
            <div className="counter-title">{title}</div>
        </div>
    );
};

export default function CounterSlider() {
    const settings = {
        autoplay: true,
        items: 1,
        nav: false,
        dots: false,
        loop: true,
        autoplayHoverPause: true,
        animateOut: 'animate__slideOutUp',
        animateIn: 'animate__slideInUp',
        autoplayTimeout: 3000,
        autoplayHoverPause: false,
    };

    return (
        <OwlCarousel className="inner-container counter-slider owl-carousel owl-theme" {...settings}>
            {/* Counter Section */}
            <div className="fact-counter">
                <div className="row clearfix">
                    <div className="column counter-column col-lg-3 col-md-6 col-sm-12">
                        <Counter stop="1200" speed="4000" title="Wikipedia Profiles" />
                    </div>
                    <div className="column counter-column col-lg-3 col-md-6 col-sm-12">
                        <Counter stop="10" speed="3000" title="Years of Experience" />
                    </div>
                    <div className="column counter-column col-lg-3 col-md-6 col-sm-12">
                        <Counter stop="150" speed="3000" title="Countries Reached" />
                    </div>
                    <div className="column counter-column col-lg-3 col-md-6 col-sm-12">
                        <Counter stop="1000" speed="4000" title="Happy Clients" />
                    </div>
                </div>
            </div>

            {/* Logo Carousel */}
            <ul className="c-logo">
                <li>
                    <img loading="lazy" src={logoFor} alt="Logo 1" />
                </li>
                <li>
                    <img loading="lazy" src={logoFor1} alt="Logo 2" />
                </li>
                <li>
                    <img loading="lazy" src={logoFor2} alt="Logo 3" />
                </li>
                <li>
                    <img loading="lazy" src={logoFor3} alt="Logo 4" />
                </li>
                <li>
                    <img loading="lazy" src={logoFor4} alt="Logo 5" />
                </li>
                <li>
                    <img loading="lazy" src={logoFor5} alt="Logo 6" />
                </li>
            </ul>
        </OwlCarousel>
    );
}

