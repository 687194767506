import React, { useState } from 'react';

const AccordionItem = ({ count, question, answer, isActive, onToggle }) => {
    return (
        <li className={`accordion block ${isActive ? 'active-block' : ''}`}>
            <div className={`acc-btn ${isActive ? 'active' : ''}`} onClick={onToggle}>
                <span className="count">{count}.</span> {question}
            </div>
            <div className="acc-content" style={{ display: isActive ? 'block' : 'none' }}>
                <div className="content">
                    <div className="text">{answer}</div>
                </div>
            </div>
        </li>
    );
};

export default function FAQsSection() {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqData = [
        {
            question: 'How can I edit without having a Wikipedia account?',
            answer: `Our wiki writers are trained and authorized in editing a Wikipedia page or articles. You don't have to worry about editing your Wikipedia page yourself since we provide top-quality services.`,
        },
        {
            question: 'How can I monitor my article?',
            answer: `As part of our services, we provide monitoring. You'll be notified when Wikipedia editors make any changes to your business page.`,
        },
        {
            question: 'What information can you not put on Wikipedia?',
            answer: `Wikipedia is not a forum for advocacy, advertisement, vanity publishing, or a site directory. It’s not a dictionary, a journal, or a reference list.`,
        },
        {
            question: 'What is the role of a Wikipedia page editor?',
            answer: `We have different writers handling various parts of our services. A Wikipedia content writer creates your draft, and our editing services ensure quality work.`,
        },
        {
            question: 'How difficult is writing a Wikipedia page?',
            answer: `Our wiki writers are trained in all aspects of writing or adding Wikipedia pages. We provide competent writers for hire, and our specialized article writers find writing Wikipedia easy.`,
        },
        {
            question: 'How much do our Wikipedia services cost?',
            answer: `We only accept orders that follow Wikipedia Notability requirements. We provide a variety of packages to meet your specific needs.`,
        },
    ];

    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <section className="faqs-section">
            <div className="auto-container">
                <div className="sec-title centered">
                    <h2>
                        Got questions? <br /> <span className="dot">Let us answer!</span>
                    </h2>
                </div>
                <div className="row clearfix">
                    {/* Left Column */}
                    <div className="faq-block col-lg-6 col-md-12 col-sm-12">
                        <ul className="accordion-box clearfix">
                            {faqData.slice(0, 3).map((faq, index) => (
                                <AccordionItem
                                    key={index}
                                    count={index + 1}
                                    question={faq.question}
                                    answer={faq.answer}
                                    isActive={activeIndex === index}
                                    onToggle={() => handleToggle(index)}
                                />
                            ))}
                        </ul>
                    </div>
                    {/* Right Column */}
                    <div className="faq-block col-lg-6 col-md-12 col-sm-12">
                        <ul className="accordion-box clearfix">
                            {faqData.slice(3).map((faq, index) => (
                                <AccordionItem
                                    key={index + 3}
                                    count={index + 4}
                                    question={faq.question}
                                    answer={faq.answer}
                                    isActive={activeIndex === index + 3}
                                    onToggle={() => handleToggle(index + 3)}
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
