import React, { useEffect, useState } from 'react';
import './App.css';
import { loader, awradBannerTrustImg1, awradBannerTrustImg2, awradBannerTrustImg3, footerLogo, iconDmca, iconPaypal, logoAffordable, logoEditing, logoFlowchart, logoMaintainence, logoPageCreation, logoPageTranslation, logoPageUpdates, logoResearch, logoSatisfaction, logoServices, logoVastExperience, wiki, wikimp4 } from './assets/images';
import CounterSlider from './Components/CounterSlider';
import FAQsSection from './Components/FAQsSection';
import TestimonialCarousel from './Components/TestimonialCarousel';
import TestimonialSlider from './Components/TestimonialSlider';
import { WEBSITE_NAME, PHONE, PHONE_HREF, EMAIL, EMAIL_HREF } from './config';
import { ModalSection } from './Components/ModalSection';
import axios from 'axios';

function App() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [open, setOpen] = useState(false);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Function to open chat window
    const openChatWindow = () => {
      if (window.$zopim) {
        window.$zopim.livechat.window.show();
      }
    };

    // Function to toggle chat window
    const toggleChat = () => {
      if (window.$zopim) {
        window.$zopim.livechat.window.toggle();
      }
    };

    // Initialize chat and open by default
    const initializeChat = () => {
      const intervalId = setInterval(() => {
        if (window.$zopim && window.$zopim.livechat) {
          clearInterval(intervalId);
          openChatWindow();
          listenForNewMessages();
        }
      }, 100); // Check every 100ms if chat widget is available
    };

    // Listen for new messages
    const listenForNewMessages = () => {
      if (window.$zopim && window.$zopim.livechat) {
        window.$zopim.livechat.setOnUnreadMsgs((numberOfUnreadMessages) => {
          if (numberOfUnreadMessages > 0) {
            openChatWindow();
          }
        });
      }
    };

    initializeChat();

    const chatButtons = document.querySelectorAll('.chat'); // Select all buttons with the class 'chat'

    chatButtons.forEach(button => {
      button.addEventListener('click', toggleChat); // Add click event to each button
    });

    // Cleanup: Remove event listeners when the component unmounts
    return () => {
      chatButtons.forEach(button => {
        button.removeEventListener('click', toggleChat); // Remove click event from each button
      });
    };
  }, []);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    brief: '',
    phone: '',
  });

  const onSubmitFunc = async (e) => {
    e.preventDefault();

    console.log('formData', formData);
    if (formData.phone.length < 9) {
      setErrors(true);
    } else {
      const currentUrl = window.location.href;
      const protocol = window.location.protocol; // "https:"
      const hostname = window.location.hostname; // "creativeghostwriters.org"

      // Construct the base URL
      const baseUrl = `${protocol}//${hostname}`;
      const queryStringFormData = new URLSearchParams(formData).toString();

      let finalReq = {
        ...formData,
        source: `${currentUrl}${queryStringFormData}`,
        domain: baseUrl,
        lead_url: currentUrl,
        url: `${currentUrl}${queryStringFormData}`,
      };
      try {
        setLoading(true);
        const apiUrl = 'https://tgcrm.net/api/form_submission?brand_key=949201';
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        };

        const formDataString = new URLSearchParams(finalReq).toString();

        const response = await axios.post(apiUrl, formDataString, config);
        if (response.status == 200) {
          // toast.success("Thank you for filling out the information");
          // console.log(response.data.data.id);
          const queryString = new URLSearchParams(
            response.data.data
          ).toString();
          console.log('queryString', queryString);
          setLoading(false);
          setErrors(false);
          setFormData({
            name: '',
            email: '',
            message: '',
            phone: '',
          });
          // naviagte(`/thank-you?${queryString}`)
          window.location.href = `https://www.professionalwikicreators.com/thankyou?${queryString}`;
        }
        console.log('responseresponseresponse', response);
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };


  return (
    <div className="App">
      <header className={`main-header header-style-two ${isScrolled ? 'color-bg' : ''}`}>
        <div class="header-upper">
          <div class="inner-container clearfix container">
            <div class="logo-box">
              <div class="logo">
                <a href="/get-your-wikipedia"> <img src={footerLogo} alt="logo" width="200" height="100" />
                </a>
              </div>
            </div>
            <div class="other-links clearfix">
              <div class="link-box">
                <ul>
                  <li class="marg-l">
                    <div class="call-us">
                      <a class="link" href={PHONE_HREF}> <span class="icon call"></span>
                        <span class="sub-text">Call Anytime</span> <span class="number">
                          {PHONE}
                        </span> </a>
                    </div>
                  </li>
                  <li>
                    <div class="call-us">
                      <a class="link chat" href="javascript:;"> <span class="icon"></span> <span class="sub-text">24/7 Support </span> <span class="number">Start Live
                        Chat</span> </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="link-box"> </div>
            </div>
          </div>
        </div>
      </header>

      <section class="banner-section">
        <div class="bg-image"></div>
        <div class="auto-container tp-d">
          <div class="row align-items-center justify-content-between">
            <div class="col-md-6">
              <div class="content-box">
                <h1>add yourself to wikipedia and get recognized globally</h1>
                <div class="text">Expert Wikipedia Services Are Just A Click Away!</div>
                <ul class="trust-lst">
                  <li>
                    <a href="javascript:;"><img src={awradBannerTrustImg1} /></a>
                  </li>
                  <li>
                    <a href="javascript:;"><img src={awradBannerTrustImg2} /></a>
                  </li>
                  <li>
                    <a href="javascript:;"><img src={awradBannerTrustImg3} /></a>
                  </li>
                </ul>
                <div class="get_btn">
                  <ul class="cc-lst">
                    <li>
                      <button class="theme-btn btn-style-two signUpOpen" type="submit" onClick={onOpenModal}> <i class="btn-curve"></i> <span class="btn-title">Order
                        Now</span> </button>
                    </li>
                    <li>
                      <button class="theme-btn btn-style-one chat" type="submit"> <i class="btn-curve"></i>
                        <span class="btn-title">Live
                          Chat</span> </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-5 ma-pp">
              <div class="content-wrp">
                <form class="form_submission" method="POST" onSubmit={onSubmitFunc}>
                  <div class="cont-bx">
                    <h3 class="text-center">Get 50% Discount</h3>
                    <h1 class="text-center">On Creating a Wikipedia Page</h1>
                    <div class="l-field">
                      <label>Your Name</label>
                      <input type="text" name="name" placeholder="" required onChange={(e) => setFormData({ ...formData, name: e.target.value })}/>
                    </div>
                    <div class="l-field">
                      <label>Email Address</label>
                      <input type="email" placeholder="" name="email" required onChange={(e) => setFormData({ ...formData, email: e.target.value })}/>
                    </div>
                    <div class="l-field">
                      <label>Phone Number</label>
                      <input type="number" name="phone" minlength="10" maxlength="12" class="phone-number" placeholder="" required  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}/>
                    </div>
                    <div class="l-field">
                      <label>Message</label>
                      <textarea name="message" placeholder="" required onChange={(e) => setFormData({ ...formData, message: e.target.value })}></textarea>
                    </div>
                    <div class="l-field text-right">
                      {loading == true ? (
                        <div class="loader">
                          <img alt="loader" src={loader} />
                        </div>
                      ) : (
                        <button class="theme-btn btn-style-one" type="submit" value="submit"> <i class="btn-curve"></i>
                          <span class="btn-title">Activate your
                            Coupon</span>
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="facts-section">
        <div class="auto-container">
          <CounterSlider />
        </div>
      </section>

      <section class="cta_cover">
        <div class="auto-container">
          <div class="row align-items-center">
            <div class="col-md-6"> <img src={wiki} alt="" width="100%" /> </div>
            <div class="col-md-6">
              <div class="inner">
                <h2>Professional Wikipedia Page Creation Services</h2>
                <p>When the digital market is fuming with fierce competition and a drastic need to bring
                  innovation is the need of the hour no matter in which field you operate, this is high
                  time to trust us.</p>
                <p>We bring you a wide range of Custom Wikipedia page creation services to help you get
                  acknowledged and noticed in no time. We can bring you to the top charts by producing a
                  well-versed wiki page for you and your platform. With the great assistance of our
                  top-of-the-line Wikipedia Page creators, we can deliver the finest Wikipedia page
                  writing services from among the bevy of choices available. We know the challenges a
                  layperson encounters when handling a long list of wiki policies and instructions.
                  Therefore, we tailor our services to cater to you in the best way possible while
                  striving to fulfill our valued customers' unique needs and demands.</p>
                <div class="link-box text-center">
                  <a class="theme-btn btn-style-two signUpOpen" href="javascript:;" onClick={onOpenModal}> <i class="btn-curve"></i>
                    <span class="btn-title">Contact with us</span> </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="maps-section">
        <div class="auto-container">
          <div class="section-head">
            <h2 class="title">Our Services</h2>
          </div>
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.2s">
              <div class="icon-bx-wraper wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.4s">
                <div class="icon-bx-sm">
                  <a href="javascript:;" class="icon-cell"> <img src={logoResearch} /> </a>
                </div>
                <div class="icon-content">
                  <h4 class="dlab-title">Research &amp; Copy writing</h4>
                  <p>Before we get started working on Wikipedia pages, our research team conducts an
                    analysis of all there is to know.</p>
                </div>
              </div>
              <div class="icon-bx-wraper wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.6s">
                <div class="icon-bx-sm">
                  <a href="javascript:;" class="icon-cell"> <img src={logoPageTranslation} />
                  </a>
                </div>
                <div class="icon-content">
                  <h4 class="dlab-title">Page Translation</h4>
                  <p>Get it translated to a number of languages from our language experts. Accurate
                    translation with complete quality assurance.</p>
                </div>
              </div>
              <div class="icon-bx-wraper wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.8s">
                <div class="icon-bx-sm">
                  <a href="javascript:;" class="icon-cell"> <img src={logoPageCreation} />
                  </a>
                </div>
                <div class="icon-content">
                  <h4 class="dlab-title">Page Creation</h4>
                  <p>A page complies with all Wikipedia guidelines and is bound to add value to the
                    brand/individual personality.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <video
                autoPlay
                muted
                loop
                id="myVideo"
                style={{ width: '100%' }} // Ensures the video takes up 100% of its container width
              >
                <source src={wikimp4} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div class="col-lg-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0.2s">
              <div class="icon-bx-wraper wow fadeInRight active" data-wow-duration="2s" data-wow-delay="0.4s">
                <div class="icon-bx-sm">
                  <a href="javascript:;" class="icon-cell"> <img src={logoMaintainence} />
                  </a>
                </div>
                <div class="icon-content">
                  <h4 class="dlab-title">Maintenance &amp; Monitoring</h4>
                  <p>To keep you safe from any unknown and illicit alterations on your Wikipedia page, our
                    team monitors and maintain the page regularly.</p>
                </div>
              </div>
              <div class="icon-bx-wraper wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.6s">
                <div class="icon-bx-sm">
                  <a href="javascript:;" class="icon-cell"> <img src={logoEditing} />
                  </a>
                </div>
                <div class="icon-content">
                  <h4 class="dlab-title">Page Editing</h4>
                  <p>We make sure to add all information with a brilliant quality of writing in the
                    shortest time period. </p>
                </div>
              </div>
              <div class="icon-bx-wraper wow fadeInRight" data-wow-duration="2s" data-wow-delay="0.8s">
                <div class="icon-bx-sm">
                  <a href="javascript:;" class="icon-cell"> <img src={logoPageUpdates} />
                  </a>
                </div>
                <div class="icon-content">
                  <h4 class="dlab-title">Page Updates</h4>
                  <p>We make sure that you have faultless information on your Wikipedia page and keep it
                    up-to-date.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="services-section">
        <div class="auto-container">
          <div class="sec-title">
            <div class="row">
              <div class="column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <h2>working together to start a wiki page, can help in achieving milestones<span class="dot">!</span></h2>
              </div>
              <div class="column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <p class="lower-text">Providing compelling content written by professional Wikipedia
                  writers and Wikipedia authors that meet all the essential requirements. </p>
              </div>
            </div>
          </div>
          <div class="services">
            <div class="row">
              <div class="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div class="inner-box wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                  <div class="bottom-curve"></div>
                  <div class="icon-box"><img src={logoAffordable} /></div>
                  <h5><a href="javascript:;">Affordable</a></h5>
                  <p class="text">You won't find a guarantee of low cost, high standards, and
                    dependability anywhere else.</p>
                </div>
              </div>
              <div class="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div class="inner-box wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                  <div class="bottom-curve"></div>
                  <div class="icon-box"><img src={logoSatisfaction} /></div>
                  <h5><a href="javascript:;">Satisfaction</a></h5>
                  <p class="text">There are no complaints, and unlimited revisions are available.
                    Alternatively, you might get a full refund.</p>
                </div>
              </div>
              <div class="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div class="inner-box wow fadeInUp" data-wow-delay="600ms" data-wow-duration="1500ms">
                  <div class="bottom-curve"></div>
                  <div class="icon-box"><img src={logoVastExperience} /></div>
                  <h5><a href="javascript:;">Vast Experience </a></h5>
                  <p class="text">They are professional authors who produce one-of-a-kind and exclusive
                    content.</p>
                </div>
              </div>
              <div class="service-block-two col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div class="inner-box wow fadeInUp" data-wow-delay="900ms" data-wow-duration="1500ms">
                  <div class="bottom-curve"></div>
                  <div class="icon-box"><img src={logoServices} /></div>
                  <h5><a href="javascript:;">Services </a></h5>
                  <p class="text">Complete anonymity, as well as adherence to Wikipedia's standards and
                    policies.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="testimonials-four">
        <div class="auto-container">
          <div class="advan sec-title">
            <h2>start a wiki page by trusting our flawless <span class="clr">wikipedia</span> services .</h2>
            <div class="lower-text">We support your online credibility and take it to new heights.</div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <TestimonialSlider />
            </div>
          </div>
        </div>
      </section>

      <section class="call-to-section">
        <div class="container-fluid pad">
          <div class="inner clearfix">
            <div class="shape-1 wow slideInRight" data-wow-delay="0ms" data-wow-duration="1500ms"></div>
            <div class="shape-2 wow fadeInDown" data-wow-delay="0ms" data-wow-duration="1500ms"></div>
            <h2>CREATING A WIKIPEDIA PAGE WAS NEVER THIS <span class="clr2">EASY !</span> </h2>
            <p>Our representatives are available 24/7 for your help. Consult now with a Wiki Writer today.</p>
            <ul class="c-btn">
              <li>
                <div class="link-box">
                  <a class="theme-btn btn-style-two signUpOpen" href="javascript:;" onClick={onOpenModal}> <i class="btn-curve"></i>
                    <span class="btn-title">Order Now</span> </a>
                </div>
              </li>
              <li>
                <div class="link-box">
                  <a class="theme-btn btn-style-two chat" href="javascript:;"> <i class="btn-curve"></i> <span class="btn-title" onclick="setButtonURL()">Live Chat</span> </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section class="history-timeline">
        <div class="">
          <div class=" sec-title">
            <h2>about our wikipedia page <span class="clr">writing services .</span></h2>
            <div class="lower-text">{WEBSITE_NAME} is all about enhancing your presence on
              Wikipedia, you
              can make a Wikipedia page for your business or a Wikipedia page about yourself with the
              assistance of our Wikipedia experts. Our Wiki professionals work hard to ensure you great
              results for long-term credibility. </div>
          </div>
          <div class="his-pic"> <img src={logoFlowchart} width="100%" /> </div>
        </div>
      </section>

      <FAQsSection />

      <section class="testimonials-section">
        <div class="auto-container">
          <div class="sec-title">
            <h2>Customer feedbacks<span class="dot">.</span></h2>
          </div>
          <div class="carousel-box">
            <TestimonialCarousel />
          </div>
        </div>
      </section>

      <section class="get-quote-two">
        <div class="auto-container">
          <div class="row clearfix align-items-center">
            <div class="right-col col-lg-12 col-md-12 col-sm-12">
              <div class="inner">
                <div class="sec-title">
                  <h2>Get a Wikipedia page created without any hassle<span class="dot">.</span></h2>
                </div>
                <div class="form-box">
                  <div class="default-form">
                    <form class="form_submission" method="POST" onSubmit={onSubmitFunc}>
                      <div class="row clearfix">
                        <div class="form-group col-lg-6 col-md-6 col-sm-12">
                          <div class="field-inner">
                            <input type="text" name="name" placeholder="Your Name" required onChange={(e) => setFormData({ ...formData, name: e.target.value })}/>
                          </div>
                        </div>
                        <div class="form-group col-lg-6 col-md-6 col-sm-12 fotr">
                          <div class="field-inner">
                            <input type="number" maxlength="12" minlength="10" name="phone" class="phone-number" placeholder="Phone Number" required  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}/>
                          </div>
                        </div>
                        <div class="form-group col-lg-12 col-md-12 col-sm-12">
                          <div class="field-inner">
                            <input type="email" name="email" placeholder="Email Address" required onChange={(e) => setFormData({ ...formData, email: e.target.value })}/>
                          </div>
                        </div>
                        <div class="form-group col-lg-12 col-md-12 col-sm-12">
                          <div class="field-inner">
                            <textarea name="message" placeholder="Write Message" required onChange={(e) => setFormData({ ...formData, message: e.target.value })}></textarea>
                          </div>
                        </div>
                        <div class="form-group col-lg-12 col-md-12 col-sm-12">
                          {loading == true ? (
                            <div class="loader">
                              <img alt="loader" src={loader} />
                            </div>
                          ) : (
                            <button class="theme-btn btn-style-one" type="submit"> <i class="btn-curve"></i> <span class="btn-title">Activate
                              Now</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="fluid-section">
        <div class="outer-container">
          <div class="row clearfix">
            <div class="column col-lg-6 col-md-12 col-sm-12">
              <div class="inner innerone">
                <div class="image-layer">
                </div>
                <div class="content-box">
                  <h3>BUILD a BETTER personal Wikipedia ALOT QUICKER WITH {WEBSITE_NAME}</h3>
                  <div class="link-box">
                    <a class="theme-btn btn-style-one signUpOpen" href="javascript:;" onClick={onOpenModal}> <i class="btn-curve"></i> <span class="btn-title">Discover More</span> </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="column col-lg-6 col-md-12 col-sm-12">
              <div class="inner innersecond">
                <div class="image-layer">
                </div>
                <div class="content-box">
                  <h3>BUILD a BETTER business Wikipedia ALOT QUICKER WITH {WEBSITE_NAME}</h3>
                  <div class="link-box">
                    <a class="theme-btn btn-style-two signUpOpen" href="javascript:;" onClick={onOpenModal}> <i class="btn-curve"></i> <span class="btn-title">Discover More</span> </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer class="main-footer normal-padding">
        <div class="auto-container">
          <div class="widgets-section">
            <div class="row clearfix">
              <div class="column col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="footer-widget logo-widget">
                  <div class="widget-content">
                    <div class="logo">
                      <a href="/"> <img src={footerLogo} alt="" /> </a>
                    </div>
                    <div class="text">{WEBSITE_NAME}- A legendary service
                      <br />provider for Wikipedia recognition. Determined
                      <br />to provide 100% satisfaction to the customers.
                    </div>
                    <ul class="social-links clearfix">
                      <li><a href="javascript:;"><span class="fab fa-facebook-square"></span></a></li>
                      <li><a href="javascript:;"><span class="fab fa-twitter"></span></a></li>
                      <li><a href="javascript:;"><span class="fab fa-instagram"></span></a></li>
                      <li><a href="javascript:;"><span class="fab fa-pinterest-p"></span></a></li>
                      <li><a href="javascript:;"><span class="fab fa-linkedin-in"></span></a></li>
                      <li><a href="javascript:;"><span class="fab fa-youtube"></span></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="footer-widget info-widget">
                  <div class="ft-display widget-content">
                    <h6>Contact</h6>
                    <ul class="contact-info">
                      <li><i class="fas fa-phone-alt"></i><a href={PHONE_HREF}>
                        {PHONE}</a></li>
                      <li><i class="fas fa-envelope"></i><a href={EMAIL_HREF}>
                        {EMAIL}</a>
                      </li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="auto-container">
            <div class="inner clearfix">
              <div class="row align-items-center">
                <div class="col-md-8">
                  <div class="copyright">Powered by
                    <a href="#">{WEBSITE_NAME}</a>
                    2024 - All rights reserved.
                    <a target="blank" href="../term-condition.php">Terms and
                      Conditions | </a>
                    <a target="blank" href="../privacy-policy.php">Privacy
                      Policy </a>.

                  </div>
                </div>

                <div class="col-md-4">
                  <ul class="pay-lst">
                    <li>
                      <div class="pay"> <img src={iconPaypal} /> </div>
                    </li>
                    <li>
                      <div class="dmc">
                        <a href="javascript:;" title="DMCA.com Protection Status" class="dmca-badge"><img src={iconDmca} /></a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <ModalSection open={open} onCloseModal={onCloseModal} />

    </div>
  );
}

export default App;
