const Admin_Panel = 'info@professionalwikicreators.com';
const PHONE = '(323) 648-8401';
const PHONE_HREF = 'tel:3236488401';
const PHONE_TWO = '(323) 614-0787';
const PHONE_TWO_HREF = 'tel:3236140787';
const EMAIL = 'info@professionalwikicreators.com';
const EMAIL_HREF = 'mailto:info@professionalwikicreators.com';
const CONTACT_EMAIL = 'info@professionalwikicreators.com';
const CONTACT_EMAIL_HREF = 'mailto:info@professionalwikicreators.com';
const ADDRESS = '468 N Camden Dr, Beverly Hills, CA 90210';
const WEBSITE_NAME = 'Professional Wiki Creators';
const SITE_CURRENCY_SYMBOLS = '$';

export {
  Admin_Panel,
  PHONE,
  PHONE_HREF,
  PHONE_TWO,
  PHONE_TWO_HREF,
  EMAIL,
  EMAIL_HREF,
  CONTACT_EMAIL,
  CONTACT_EMAIL_HREF,
  ADDRESS,
  WEBSITE_NAME,
  SITE_CURRENCY_SYMBOLS,
};
